@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');

html {
  height: 100%;
}

body {
  background-color: black;
  background-image: url('../public/nasa-planet.jpg');
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100%;
}

.portfolio-img-wrapper::after {
  content: attr(data-date);
  position: absolute;
  top: 20px;
  left: -36px;
  padding: 0 50px;
  font-size: 1rem;
  color: white;
  background-color: #e85a43;
  transform: rotate(-45deg);
}
